//////////////////////////////////////////////////////////////
// Plotting functions 
// plot-path: Plot a path (default)
// plot-path-by-tl: Plot a path by timeline 
// plot-path-by-v: Plot a path by vertex
//   All three should specify id and path-id

var Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/timeline')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);
//require('highcharts/modules/networkgraph')(Highcharts);
//require('highcharts/modules/sand-signika')(Highcharts);
//
$(document).on("turbolinks:load", function() {
  $('.plot-path-by-tl').each(function(i,obj) {
    console.log("DEBUG:: Find %s-th plot-path-by-tl!", i);
    let oid = $(obj).attr('id')
    let pid = $(obj).attr('path-id')
    console.log("DEBUG: Get Object ID: %s", oid)
    console.log("DEBUG: Get Path ID: %s", pid)
    // Get timing-points
    url = `/paths/${pid}.json`
    $.getJSON(url,{},function(path){
  	  let pts = path['points']
      // build data for plotting
      let data = pts.map((p,i,arr) => { 
        return { 
          name: p['name'], 
          label: `arrive time: ${p['at']}`,
          description: `slack: ${p['slack']}, delay: ${p['delay']}, adjust: ${p['adjust']}, slew: ${p['slew']}, edge: ${p['edge']}, load: ${p['load']}, pin cap: ${p['pin_cap']}, fan out: ${p['fanout']}`
        }
      });
      title ="Timing Path"
      subtitle =`${path['starting_point']} => ${path['end_point']}`
      plotPathTL(oid,data,title,subtitle)
    }); 
  });
});

$(document).on("turbolinks:load", function() {
  $('.plot-path').each(function(i,obj) {
    console.log("DEBUG:: Find %s-th plot-path!", i);
    let oid = $(obj).attr('id')
    let pid = $(obj).attr('path-id')
    console.log("DEBUG: Get Object ID: %s", oid)
    console.log("DEBUG: Get Path ID: %s", pid)
    // Get timing-points
    url = `/paths/${pid}.json`
    $.getJSON(url,{},function(path){
  	  let pts = path['points']
      title ="Timing Path"
      subtitle =`${path['starting_point']} => ${path['end_point']}`
      plotPath(oid,pts,title,subtitle)
    }); 
  });
});

$(document).on("turbolinks:load", function() {
  $('.plot-path-v').each(function(i,obj) {
    console.log("DEBUG:: Find %s-th plot-path-v!", i);
    let oid = $(obj).attr('id')
    let pid = $(obj).attr('path-id')
    console.log("DEBUG: Get Object ID: %s", oid)
    console.log("DEBUG: Get Path ID: %s", pid)
    // Get timing-points
    url = `/paths/${pid}.json`
    $.getJSON(url,{},function(path){
  	  let pts = path['points']
      title ="Timing Path"
      subtitle =`${path['starting_point']} => ${path['end_point']}`
      plotPathV(oid,pts,title,subtitle)
    }); 
  });
});
//////////////////////////////////////////////////////////////////
function plotPathTL(id,data,title,subtitle){
  console.log("=====calling plotPathPL======")
  Highcharts.chart(id, {
    chart: {
        type: 'timeline'
    },
    xAxis: {
        visible: false
    },
    yAxis: {
        visible: false
    },
    title: {
        text: title
    },
    subtitle: {
        text: subtitle,
    },
    series: [{
        data: data
    }],
    accessibility: {
        screenReaderSection: {
            beforeChartFormat: '<h5>{chartTitle}</h5>' +
                '<div>{typeDescription}</div>' +
                '<div>{chartSubtitle}</div>' +
                '<div>{chartLongdesc}</div>' +
                '<div>{viewTableButton}</div>'
        },
        point: {
            valueDescriptionFormat: '{index}. {point.label}. {point.description}.'
        }
    }
  });
}

//////////////////////////////////////////////////////////////////
function plotPath(id,pts,title,subtitle){
  console.log("=====calling plotPath======")
  var l = 50; 
  Highcharts.chart(id,{
    chart: {
      //width: pts.length*200,
      //height: pts.length*100,
      backgroundColor: 'white',
      events: {
        load: function () {
          //w = this.chartWidth;  h = this.chartHeight;  
          var ren = this.renderer;
          var x=0;
          pts.forEach(function (pt, i){
            console.log(`x=${x}`)
            var b = plot_button(ren,x+10,80,pt) 
            x += b.width;
            if (i<pts.length-1){
              plot_arrow(ren,x+10,100,l);
              x += l; 
            }
          });
        }
      },
      scrollablePlotArea: {
        //minWidth: this.chartWidth, //pts.length*200,
        minWidth: pts.length*(l+150)+100, 
        scrollPositionX: 1
      },
    },
    title: {
      text: title, 
      style: {
        color: 'black'
      }
    },
    subtitle: {
      text: subtitle, 
    },
    accessibility: {
      typeDescription: 'Flowchart'
    }
  
  });
}

function plot_button(ren,x,y,p) { 
  var c = Highcharts.getOptions().colors[0];
  var text = `<i>name</i>: ${p['name']} <br> <i>arrive time</i>: ${p['at']}`;
  var text2 = `<i>name</i>: ${p['name']} <br> <i>arrive time</i>: ${p['at']} <br> <i>slack</i>: ${p['slack']} <br> <i>delay</i>: ${p['delay']} <br> <i>adjust</i>: ${p['adjust']} <br> <i>slew</i>: ${p['slew']} <br> <i>edge</i>: ${p['edge']} <br> <i>load</i>: ${p['load']} <br> <i>pin cap</i>: ${p['pin_cap']} <br> </i>fan out</i>: ${p['fanout']}`
  var b = ren.button(text,x,y)
   .on('click', function () {
     ren.label(text2, b.x, b.y+b.height+10)
       .attr({
         fill: c, 
         stroke: 'white',
         'stroke-width': 2,
         padding: 5,
         r: 5
         })
       .css({
         color: 'white'
       })
       .add();
    })
   .add()
   .shadow(true);
  return b;
}

function plot_arrow(ren,x,y,l=100) {
  var c = Highcharts.getOptions().colors[3];
  var rightArrow = ['M', 0, 0, 'L', l, 0, 'L', l-5, 5, 'M', l, 0, 'L', l-5, -5];
  var leftArrow = ['M', l, 0, 'L', 0, 0, 'L', 5, 5, 'M', 0, 0, 'L', 5, -5];
  var a = ren.path(rightArrow)
    .attr({
      'stroke-width': 2,
      stroke: c,
    })
    .translate(x, y)
    .add();
  return a;
}

//////////////////////////////////////////////////////////////////
function plotPathV(id,pts,title,subtitle){
  console.log("=====calling plotPathV======")
  var l = 50, t = 80, s=100;
  Highcharts.chart(id,{
    chart: {
      //width: pts.length*200,
      height: pts.length*(l+40)+t+t,
      backgroundColor: 'white',
      events: {
        load: function () {
          var ren = this.renderer;
          var b,a,w; 
          var y=t; 
          pts.forEach(function (pt, i){
            b = plot_button_v(ren,s,y,pt) 
            y += b.height;
            if(i==0){ w=b.width/2;};
            if (i<pts.length-1){
              a = plot_arrow_v(ren,s+w,y,l);
              y += l; 
            }
          });
        }
      }
    },
    title: {
      text: title, 
      style: {
        color: 'black'
      }
    },
    subtitle: {
      text: subtitle, 
    },
    accessibility: {
      typeDescription: 'Flowchart'
    }
  
  });
  //change menu height after show paths
  var h = $(document).height()
  $(".nav-left").height(h-156);
}

function plot_button_v(ren,x,y,p) { 
  var c = Highcharts.getOptions().colors[0];
  var text = `<i>name</i>: ${p['name']} <br> <i>arrive time</i>: ${p['at']}`;
  var text2 = `<i>name</i>: ${p['name']} <br> <i>arrive time</i>: ${p['at']} <br> <i>slack</i>: ${p['slack']} <br> <i>delay</i>: ${p['delay']} <br> <i>adjust</i>: ${p['adjust']} <br> <i>slew</i>: ${p['slew']} <br> <i>edge</i>: ${p['edge']} <br> <i>load</i>: ${p['load']} <br> <i>pin cap</i>: ${p['pin_cap']} <br> </i>fan out</i>: ${p['fanout']}`
  var b = ren.button(text,x,y)
  .on('click', function () {
     ren.label(text2, b.x+b.width+50, b.y)
       .attr({
         fill: c, 
         stroke: 'white',
         'stroke-width': 2,
         padding: 5,
         r: 5
         })
       .css({
         color: 'white'
       })
       .add();
    })
   .add()
   .shadow(true);
  return b;
}

function plot_arrow_v(ren,x,y,l=100) {
  var c = Highcharts.getOptions().colors[3];
  var downArrow = ['M', 0, 0, 'L', 0, l, 'L', 5,l-5, 'M', 0, l, 'L', -5, l-5];
  var a = ren.path(downArrow)
    .attr({
      'stroke-width': 2,
      stroke: c,
    })
    .translate(x, y)
    .add();
  return a;
}

