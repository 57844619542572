// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()


//////////////////////////////////////////////////////////////
// NOTE: It seems Rails6.1/Webpacker5.0 has bug, in production, packs/css are generated.
// However, stylesheets_pack_tag does not generate any link, so packs/css are not loaded. 
// In development, it works as extract_css=false, and the css is included in html/head/script
// As a workaround, we put css to assets, do not include it here.
require('jquery')

//////////////////////////////////////////////////////////////
require('bootstrap')
//require('bootstrap/dist/css/bootstrap.min.css')

//////////////////////////////////////////////////////////////
// This makes fa-navicon not working
//import "@fortawesome/fontawesome-free/js/all";

//////////////////////////////////////////////////////////////
//import "chartkick/chart.js"
import "chartkick/highcharts"

//////////////////////////////////////////////////////////////
// select2
import $ from 'jquery';
require('select2')
//import 'select2/dist/css/select2.min.css'
//import "select2-bootstrap-theme/dist/select2-bootstrap.min.css";


//////////////////////////////////////////////////////////////
$(document).on("turbolinks:load", function() {
  console.log("DEBUG:: turbolinks.load!")
});

//////////////////////////////////////////////////////////////
require('./utils.js')
require('./plots.js')
require('./tag.js')
// require('./menu.js')
// require('./figures.js')
