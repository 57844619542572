///////////////////////////////////////////////////////////////
// .copy-to:  copy content to 'copy-dst-id'
// .parameterized-to: parameterized content to 'param-dst-id' 
// .file_copy_link: copy link to clipboard
//   path is from 'data-path'
//   alert message from 'data-alertmsg'
// .verify-name: check if name valid 
//   name is from 'name-field-id'
//   slug is from 'slug-text-id' which is optional
// .verify-slug: check if slug valid  
//   slug is from 'slug-field-id'
// add-project-user: append '#template' to '#project-user-view' 
// project-user-view: click .delete-project-user, remove its grandparents 
// TODO: add-project-user/project-user-view: too specific, generaliz it?

// parameterize value of a filed and put it into another field
require('./parameterize.js')

$(document).on("turbolinks:load", function() {
  ///////////////////////////////////////////////////////////////
  $('form').on('input', '.copy-to', function(event) {
    console.log("copy value updated")
    let tid = $(this).attr('copy-dst-id');
    console.log("DEBUG:: The dst ID is: %s!",tid)
    let content= $(this).val().trim()
    console.log("DEBUG:: Set value of %s to is: %s!",tid,content)
    $("#"+tid).val(content);
  });

  $('form').on('input', '.parameterize-to', function(event) {
    console.log("parameterize value updated")
    let tid = $(this).attr('param-dst-id');
    console.log("DEBUG:: The dst ID is: %s!",tid)
    let content= $(this).val().trim()
    console.log("DEBUG:: The form value is: %s!",content)
    let slug = parameterize(content)
    console.log("DEBUG:: Set value of %s to is: %s!",tid,slug)
    $("#"+tid).val(slug);
  });

  ///////////////////////////////////////////////////////////////

  // Copy solution: https://getkt.com/blog/how-to-interact-with-the-clipboard-using-javascript/
  // Or use ClipboardJS
  $(".file-copy-link").click(function(){
    let content = $(this).data("path");
    let alert_msg = $(this).data("alertmsg");
    $("body").after("<textarea cols='1' rows='1' id='copyContent'>" + content + " </textarea>");
    let link = document.getElementById("copyContent");
    link.select();
    document.execCommand("Copy");
    $("#copyContent").remove();
    alert(alert_msg);
  });

  ///////////////////////////////////////////////////////////////
  $('.verify-name').click(function(){
    console.log("verify name")
    let nid = $(this).attr('name-field-id');
    console.log("DEBUG:: The name field ID is: %s!",nid)
    let name= $("#"+nid).val().trim(); 
    console.log("DEBUG:: The name to verify is %s!",name)
    let json={"name": name};
    let sid = $(this).attr('slug-text-id');
    console.log("DEBUG:: The slug field ID is: %s!",sid)
    if (sid){ 
      let slug= $("#"+sid).text().trim(); 
      console.log("DEBUG:: The slug is: %s!",slug)
      if(slug){
        json['slug'] = slug; 
      }
    }
    console.log("DEBUG:: The json is: %s!",JSON.stringify(json))
    $.ajax({
        url: "/auth/verify_name.json",
        type: "GET",
        data: json,
        success:function(json){
          alert(json.message);
        },
        error:function(error){
          alert(error.message);
        },
    })
  });

  $('.verify-slug').click(function(){
    console.log("verify slug")
    let sid = $(this).attr('slug-field-id');
    console.log("DEBUG:: The slug field ID is: %s!",sid)
    let slug= $("#"+sid).val().trim(); 
    console.log("DEBUG:: The slug is: %s!",slug)
    let json = {"slug": slug};
    $.ajax({
        url: "/auth/verify_slug.json",
        type: "GET",
        data: json,
        success:function(json){
          alert(json.message);
        },
        error:function(error){
          alert(error.message);
        },
    })
  });

  ///////////////////////////////////////////////////////////////
  $(".add-project-user").click(function(){
    let puv = $("#project-user-view");
    puv.append($('#template').html());
  });

  $('#project-user-view').delegate(".delete-project-user", "click", function () {
    $(this).parent().parent().remove();
  });

});
